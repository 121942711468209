<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-6">
                                <h3>
                                    Results
                                </h3>
                                <router-link :to="{name:'dashboard'}">
                                    Dashboard
                                </router-link>
                                \
                                Results
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-custom mt-2">
            <div class="card-body">
                <form @submit.prevent="" class="row bg-filter">
                    <div class="col-lg-5 col-md-5  col-12" >
                        <v-select
                                :loading="loading"
                                outlined
                                dense
                                label="Semester/Year"
                                item-text="grade_title"
                                item-value="grade_id"
                                v-model="search.grade_id"
                                :items="grades">

                        </v-select>

                    </div>
                    <div class="col-lg-5 col-md-5  col-12">
                        <v-select
                                :loading="loading"
                                outlined
                                dense
                                label="Exams"
                                item-text="exam_title"
                                item-value="exam_id"
                                v-model="search.exam_id"
                                :items="exams"
                        >

                        </v-select>

                    </div>
                    <div class="col-lg-2">
                        <button
                                class=" btn btn-primary font-size-lg"
                                @click="getResult"
                                name="phone"
                                ref="phone"
                        >Filter
                        </button>
                    </div>
                </form>

            </div>
        </div>
        <div v-if="loading">
            <div class="row">
                <div class="col-md-9">
                    <v-skeleton-loader
                            type="list-item-two-line"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                            type="list-item-two-line"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                            type="list-item-two-line"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                            type="list-item-two-line"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                            type="list-item-two-line"
                    ></v-skeleton-loader>

                </div>

                <div class="col-md-3">
                    <v-skeleton-loader
                            type="card"
                    ></v-skeleton-loader>
                </div>
            </div>

        </div>
        <div v-if="!loading && results && results.courses">
            <div class="row mt-2">
                <div class="col-md-8">
                    <div class="">
                        <div class="card-body">
                            <div class="row attendance-course-list pt-2 pb-2" v-for="(item,index) in results.courses"
                                 :key="index">
                                <div class="col-12 align-items-center">
                                 <div class="d-flex flex justify-content-between">
                                     <h4>{{item.exam_course_title}}  </h4>
                                     <div>
                                         Total Grade : <v-chip>{{item.total_grade || 'N/A'}}</v-chip>
                                     </div>
                                 </div>
                                    <div class="d-flex flex justify-content-between mt-2">
                                        <strong
                                                class="mr-1 font-size-lg"
                                        >
                                            Theory
                                        </strong>
                                    <div>
                                        Obtain Mark :
                                        <v-chip
                                                color="primary"
                                                class="mr-1"
                                        >
                                            {{item.theory_grade || 0}}
                                        </v-chip>
                                    </div>
                                        <div>
                                            <h2>    {{item.theory_obtained_marks || 0}} /100  </h2>
                                            <p>Marks Obtain</p>
                                        </div>
                                    </div>
                                </div>

                                <hr>
                                <div class="col-12 align-items-center"  v-if="item.practical_obtained_marks">
                                    <div class="d-flex flex justify-content-between mt-2">
                                        <v-chip
                                                class="mr-1"
                                        >
                                            Practical
                                        </v-chip>
                                        <div>
                                            Obtain Mark :
                                            <v-chip
                                                    color="primary"
                                                    class="mr-1"
                                            >
                                                {{item.practical_grade || 0}}
                                            </v-chip>
                                        </div>

                                        <div>
                                            <h2>    {{item.practical_obtained_marks || 0}}  </h2>
                                            <p>Marks Obtain</p>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-12">
                                    <p class="mt-1">Remarks:<br/> {{item.remarks || 'N.A'}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-4" v-if="results">
                    <div class="card">
                        <div class="card-body">
                            <div class="card card-custom card-stretch card-shadowless gutter-b">
                                <div class="card-header border-0 pt-5">
                                    <div class="card-title font-weight-bolder">
                                        <div class="card-label">
                                            Result Summary
                                            <a href="#"    @click.prevent="openResultPdf(exam_id, 'certificate')">download </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div id="apexcharts9bu4igvm"
                                         class="apexcharts-canvas apexcharts9bu4igvm apexcharts-theme-light"
                                         style="width: 313px; height: 176.26px;">
                                        <svg
                                                id="SvgjsSvg1359"
                                                width="313"
                                                height="176.259765625"
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xmlns:svgjs="http://svgjs.com/svgjs"
                                                class="apexcharts-svg"
                                                xmlns:data="ApexChartsNS"
                                                transform="translate(0, 0)"
                                                style="background: transparent;"
                                        >
                                            <g id="SvgjsG1361" class="apexcharts-inner apexcharts-graphical"
                                               transform="translate(44.5, 0)">
                                                <defs id="SvgjsDefs1360">
                                                    <clipPath id="gridRectMask9bu4igvm">
                                                        <rect id="SvgjsRect1363" width="232" height="250" x="-3" y="-1"
                                                              rx="0" ry="0" opacity="1" stroke-width="0" stroke="none"
                                                              stroke-dasharray="0" fill="#fff"></rect>
                                                    </clipPath>
                                                    <clipPath id="gridRectMarkerMask9bu4igvm">
                                                        <rect id="SvgjsRect1364" width="230" height="252" x="-2" y="-2"
                                                              rx="0" ry="0" opacity="1" stroke-width="0" stroke="none"
                                                              stroke-dasharray="0" fill="#fff"></rect>
                                                    </clipPath>
                                                </defs>
                                                <g id="SvgjsG1365" class="apexcharts-radialbar">
                                                    <g id="SvgjsG1366">
                                                        <g id="SvgjsG1367" class="apexcharts-tracks">
                                                            <g id="SvgjsG1368"
                                                               class="apexcharts-radialbar-track apexcharts-track"
                                                               rel="1">
                                                                <path
                                                                        id="apexcharts-radialbarTrack-0"
                                                                        d="M 29.71097560975609 112.99999999999999 A 83.28902439024391 83.28902439024391 0 0 1 196.28902439024392 113"
                                                                        fill="none"
                                                                        fill-opacity="1"
                                                                        stroke="rgba(225,233,255,0.85)"
                                                                        stroke-opacity="1"
                                                                        stroke-linecap="round"
                                                                        stroke-width="10.636585365853659"
                                                                        stroke-dasharray="0"
                                                                        class="apexcharts-radialbar-area"
                                                                        data:pathOrig="M 29.71097560975609 112.99999999999999 A 83.28902439024391 83.28902439024391 0 0 1 196.28902439024392 113"
                                                                ></path>
                                                            </g>
                                                        </g>
                                                        <g id="SvgjsG1370">
                                                            <g id="SvgjsG1375"
                                                               class="apexcharts-series apexcharts-radial-series"
                                                               seriesName="Progress" rel="1" data:realIndex="0">
                                                                <path
                                                                        id="SvgjsPath1376"
                                                                        d="M 29.71097560975609 112.99999999999999 A 83.28902439024391 83.28902439024391 0 0 1 169.80297804535178 52.08626370794575"
                                                                        fill="none"
                                                                        fill-opacity="0.85"
                                                                        stroke="rgba(105,147,255,0.85)"
                                                                        stroke-opacity="1"
                                                                        stroke-linecap="round"
                                                                        stroke-width="10.636585365853659"
                                                                        stroke-dasharray="0"
                                                                        class="apexcharts-radialbar-area apexcharts-radialbar-slice-0"
                                                                        data:angle="133"
                                                                        data:value="12"
                                                                        index="0"
                                                                        j="0"
                                                                        data:pathOrig="M 29.71097560975609 112.99999999999999 A 83.28902439024391 83.28902439024391 0 0 1 169.80297804535178 52.08626370794575"
                                                                ></path>
                                                            </g>
                                                            <circle id="SvgjsCircle1371" r="77.97073170731709" cx="113"
                                                                    cy="113" class="apexcharts-radialbar-hollow"
                                                                    fill="transparent"></circle>
                                                            <g id="SvgjsG1372" class="apexcharts-datalabels-group"
                                                               transform="translate(0, 0) scale(1)" style="opacity: 1;">
                                                                <text
                                                                        id="SvgjsText1373"
                                                                        font-family="Helvetica, Arial, sans-serif"
                                                                        x="113"
                                                                        y="108"
                                                                        text-anchor="middle"
                                                                        dominant-baseline="auto"
                                                                        font-size="13px"
                                                                        font-weight="700"
                                                                        fill="#b5b5c3"
                                                                        class="apexcharts-text apexcharts-datalabel-label"
                                                                        style="font-family: Helvetica, Arial, sans-serif;"
                                                                >
                                                                    Progress
                                                                </text>
                                                                <text
                                                                        id="SvgjsText1374"
                                                                        font-family="Helvetica, Arial, sans-serif"
                                                                        x="113"
                                                                        y="89"
                                                                        text-anchor="middle"
                                                                        dominant-baseline="auto"
                                                                        font-size="30px"
                                                                        font-weight="700"
                                                                        fill="#464e5f"
                                                                        class="apexcharts-text apexcharts-datalabel-value"
                                                                        style="font-family: Helvetica, Arial, sans-serif;"
                                                                >
                                                                    12%
                                                                </text>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <line id="SvgjsLine1377" x1="0" y1="0" x2="226" y2="0" stroke="#b6b6b6"
                                                      stroke-dasharray="0" stroke-width="1"
                                                      class="apexcharts-ycrosshairs"></line>
                                                <line id="SvgjsLine1378" x1="0" y1="0" x2="226" y2="0"
                                                      stroke-dasharray="0" stroke-width="0"
                                                      class="apexcharts-ycrosshairs-hidden"></line>
                                            </g>
                                            <g id="SvgjsG1362" class="apexcharts-annotations"></g>
                                        </svg>
                                        <div class="apexcharts-legend"></div>
                                    </div>
                                </div>
                                <div class="mt-n12 position-relative zindex-0">
                                    <div class="d-flex align-items-center mb-8">
                                        <div>
                                            <a href="#"
                                               class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">{{results.result}}</a>
                                            <div class="font-size-sm  font-weight-bold mt-1">Congratulations you have
                                                passed
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-8">
                                        <div class="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                            <div class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                            <img src="https://img.icons8.com/fluency-systems-filled/24/000000/ranking.png"
                                 class="h-50"/>
                        </span>
                                            </div>
                                        </div>
                                        <div>
                                            <a href="#"
                                               class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Your
                                                Rank: {{results.rank || 'N/A}' }}</a>
                                            <div class="font-size-sm  font-weight-bold mt-1">Your position in exam</div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="symbol symbol-circle symbol-40 symbol-light mr-3 flex-shrink-0">
                                            <div class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                            <img src="https://img.icons8.com/ios-filled/24/000000/question-mark.png" class="h-24"/>
                        </span>
                                            </div>
                                        </div>
                                        <div>
                                            <a href="#"
                                               class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">CGPA:
                                                {{results.total_gpa || 'N/A'}}</a>
                                            <a href="#" v-if="results.division"
                                               class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Division
                                                :
                                                {{results.total_gpa || 'N/A'}}</a>
                                            <div class="font-size-sm  font-weight-bold mt-1">Total Marks :
                                                {{results.total_obtained_mark}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10">
                                        <a href="#" class="btn btn-primary">View/Download Marksheet</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-12 text-center">
                <v-card class="p-20">
                    <img src="@/assets/media/images/no-data-found.png">
                    <h5 class="mt-10">No Results available. Results will appear once Result Published to your class.</h5>
                </v-card>
            </div>
        </div>
    </v-app>
</template>

<script>
    import StudentResultService from "@/core/services/user/student/result/StudentResultService";
    import UserService from "@/core/services/user/UserService";
    import ExamService from "@/core/services/exam/ExamService";

    const studentResultService = new StudentResultService;
    const userService = new UserService();
    const examService = new ExamService;
    import {API_URL} from "@/core/config";

    export default {
        name: "Results",
        data() {
            return {
                examId: 0,
                gradeId: null,
                examResults: [],
                exams: [],
                results: null,
                grades: [],
                user: null,
                loading: false,
                studentSetting: null,
                search: {
                    exam_id: null,
                    grade_id: null,

                }

            }
        },
        watch: {
            examId(newValue, oldValue) {
                this.getResultByExams();
            },
            // gradeId(newValue, oldValue) {
            //     this.getExamsByGrade();
            // }
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
            exam_id(){
                return this.$route.params.exam_id
            },
            grade_id(){
                return this.$route.params.grade_id

            }

        },
        mounted() {
            // this.getGrade();
            this.search.exam_id = this.exam_id;
            this.search.grade_id = this.grade_id;
            this.getResult()
        },
        methods: {
            getAllExams() {
                this.loading = true;
                examService.all().then(response => {
                    this.exams = response.data.exams;
                }).catch(err => {

                }).finally(() => {
                    this.loading = false;

                })
            },
            getResult() {
                this.loading = true;
                examService.getResult(this.search).then(response => {
                    this.results = response.data.exams;
                }).catch(err => {

                }).finally(() => {
                    this.loading = false;

                })
            },
            getGrade() {
                this.loading = true;
                examService.getGrade().then(response => {
                    this.grades = response.data.history.grade_history;
                    this.exams = response.data.history.exam_history;
                }).catch(err => {

                }).finally(() => {
                    this.loading = false;

                })
            },
            // getUser() {
            //     this.loading = true
            //     userService.show(this.currentUser.id).then(response => {
            //         this.user = response.data.user;
            //         this.user.is_active ? (this.user.is_active = true) : false;
            //         this.user.is_email_verified
            //             ? (this.user.is_email_verified = true)
            //             : false;
            //         this.studentSetting = this.user.setting ? this.user.setting : {}
            //         this.gradeId = this.studentSetting.grade_id;
            //         this.getExamsByGrade();
            //     }).finally(() => {
            //         this.loading = false;
            //
            //     });
            // },
            getStudentClass() {
                studentResultService.getGrades().then((res) => {
                    this.grades = res.data.classes;
                })
            },
            getExamsByGrade() {
                this.loading = true
                studentResultService.getExamsByGrades().then((res) => {
                    this.examResults = res.data.exams;
                    if (this.examResults.length) {
                        this.examId = this.examResults[0].id;
                        this.getResultByExams();
                    }
                    // console.log(res)
                }).catch(err => {
                    this.loading = false;
                }).finally(() => {
                    this.loading = false;
                })
            },
            getResultByExams() {
                this.$bus.emit('toggleLoader',);
                this.loading = true
                studentResultService.getResultByExams(this.examId).then((res) => {
                    this.results = res.data.result;
                    this.$bus.emit('toggleLoader',);
                    // console.log(res)
                }).finally(() => {
                    this.loading = false;
                })
            },
            openResultPdf(examId, type) {
                window.open(
                    // result/session/{examId}/{type}
                    API_URL + "user/student/result/session/" + examId + '/'+ type,
                    "_blank"
                );
            },

        },
    }
</script>

<style scoped>

</style>
