import Apiservice from '../../../api.service';
import {API_URL} from '../../../../../common/config'

const apiService = Apiservice;


export default class StudentResultService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student`;
    }

    // paginate() {
    //     let url = `${this.#api}/student-result`;
    //     return apiService.get(url);
    // }
    getGrades(){
        let url = `${this.#api}/results/class/list`;
        return apiService.get(url);
    }
    getExamsByGrades(){
        let url = `${this.#api}/results/exams/list`;
        return apiService.get(url);
    }
    getResultByExams(examId){
        let url = `${this.#api}/results/exams/${examId}/view`;
        return apiService.get(url);
    }

    // update( id, data) {
    //     let url = `${this.#api}/${userId}/student-result/${id}`
    //     return apiService.put(url, data);
    //
    // }
    //
    // store(userId, data) {
    //     let url = `${this.#api}/${userId}/student-result`
    //     return apiService.post(url, data);
    //
    // }
    //
    // show(userId, id) {
    //     let url = `${this.#api}/${userId}/student-result/${id}`
    //     return apiService.get(url);
    // }
    //
    // delete(userId, id) {
    //     let url = `${this.#api}/${userId}/student-result/${id}`
    //     return apiService.delete(url);
    // }
    //
    // saveStudentResultByCourse(examId, data) {
    //     let url = `${this.#api}/exam/${examId}/student-course-result/save`
    //     return apiService.post(url,data);
    // }
    //
    // saveStudentResult(examId, data) {
    //     let url = `${this.#api}/exam/${examId}/student-result/save`
    //     return apiService.post(url,data);
    // }
    // getAllByExamId(examId) {
    //     let url = `${this.#api}/exam/${examId}/results`
    //     return apiService.get(url);
    // }

}
